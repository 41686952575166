import React from 'react'
import SEO from "../common/SEO";
import Footer from '../common/footer/_Footer';
import TopBar from '../common/header/_TopBar';
import Header from '../common/header/_Header';
import ContactOne from '../elements/contact/ContactOne';
import PhotoThanks from '../common/PhotoThanks';
import GATracker from '../gaTracker';

const Contatti = () => {

    GATracker();

    return (
        <>
            <SEO title="Contatti" description={"Operiamo su"} />
            <main className="page-wrapper">
                
            <TopBar />
            <Header btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-550 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-contatti.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Contatti</span></h3>
                                    </div>
                                    <h1 className="title display-one">I nostri recapiti</h1>                                    
                                </div>                                
                            </div>                            
                        </div>
                    </div>                    
                </div>
                <PhotoThanks name="John Schnobrich" link="johnschno" />
                {/* End Slider Area  */}              

                {/* Start Contact Area  */}
                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        
                        <ContactOne />
                    </div>
                </div>
                {/* End Contact Area  */}

                <Footer />

            </main>
        </>
    )
}

export default Contatti;
