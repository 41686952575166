import React from 'react'
import SEO from "../common/SEO";
import Footer from '../common/footer/_Footer';
import Separator from "../elements/separator/Separator";
import TopBar from '../common/header/_TopBar';
import Header from '../common/header/_Header';
import PhotoThanks from '../common/PhotoThanks';
import BoxRealizzazioni from '../elements/_BoxRealizzazioni';
import BoxWeb from '../elements/_BoxWeb';
import BoxEcommerce from '../elements/_BoxEcommerce';
import GATracker from '../gaTracker';

const SviluppoWeb = () => {

    GATracker();

    return (
        <>
            <SEO title="Sviluppo siti web, siti e-commerce" description={"Sviluppo siti web, siti e-commerce"} />
            <main className="page-wrapper">

                <TopBar />
                <Header btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-550 bg_image" data-black-overlay="7" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-web.jpg)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <h1 className="title display-one">Realizzazione siti<br />Web & eCommerce</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PhotoThanks name="rupixen.com" link="rupixen" />
                {/* End Slider Area  */}

                <Separator />

                <BoxWeb />

                <Separator />

                <BoxEcommerce />               

                <Separator />

                <BoxRealizzazioni />

                <Footer />

            </main>
        </>
    )
}

export default SviluppoWeb;
