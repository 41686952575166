import React from 'react';
import { FiFacebook, FiTwitter, FiChevronRight, FiMapPin, FiPhone } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import {Link} from "react-router-dom";

const TopBar = () => {
    return (
        <div className="header-top-bar d-none d-md-block">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="header-left">
                            <Link to="/contatti?t=Richiesta assistenza">Richiedi assistenza <FiChevronRight /></Link>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                        <div className="header-right">
                            <div className="address-content">
                                <p><FiMapPin /><span>Milano, Como, Varese, Monza e Brianza</span></p>
                                <p><FiPhone /><span><a href="#">3335903752</a></span></p>
                            </div>
                            <div className="social-icon-wrapper">
                                <ul className="social-icon social-default icon-naked">
                                    <li><a href="https://www.facebook.com/PizziWeb/" target='_blank' ><FiFacebook /></a></li>
                                    <li><a href="https://twitter.com/pizziweb" target='_blank' ><FiTwitter /></a></li>
                                    <li><a href="https://api.whatsapp.com/send?phone=+393335903752" target='_blank' ><FaWhatsapp /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopBar;
