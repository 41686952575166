import React from 'react'
import SEO from "../common/SEO";
import Footer from '../common/footer/_Footer';
import Separator from "../elements/separator/Separator";
import TopBar from '../common/header/_TopBar';
import Header from '../common/header/_Header';
import PhotoThanks from '../common/PhotoThanks';
import BoxServizi from '../elements/_BoxServizi';
import BoxMetodo from '../elements/_BoxMetodo';
import GATracker from '../gaTracker';

const ChiSiamo = () => {

    GATracker();

    return (
        <>
            <SEO title="Chi Siamo" description={"Sviluppo software"} />
            <main className="page-wrapper">

                <TopBar />
                <Header btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-550 bg_image" data-black-overlay="7" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-chisiamo.jpg)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Chi siamo</span></h3>
                                    </div>
                                    <h1 className="title display-one">Soluzioni informatiche<br />su misura.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PhotoThanks name="Alesia Kazantceva" link="alesiaskaz" />
                {/* End Slider Area  */}

                <Separator />

                {/* Start Text Area  */}
                <div className="service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">
                                        Soluzioni informatiche su misura per la tua azienda e le tue necessità.<br /><br />
                                        Anni di esperienza nel settore dell'informatica ci consentono di <span class="highlighted">intervenire su più fronti</span> e nelle modalità che preferisce il cliente.<br />
                                    </h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">  <br />
                                    Lavoriamo a soluzioni specifiche per aziende in provincia di <span class="highlighted">Milano, Como, Varese, Monza e Brianza.</span><br /><br />
                                    Mediante l'utilizzo di tecnologie sempre più innovative <span class="highlighted">proponiamo soluzioni efficaci ed intuitive</span> con interfacce grafiche accattivanti, facili da utilizzare e da mantenere aggiornate.
                                    <br />
                                    <br />
                                    I nostri strumenti ti permettono di pubblicizzare la tua attività in modo <span class="highlighted">veloce e dinamico</span> senza tralasciare l'aspetto grafico e la compatibilità con i motori di ricerca.
                                    <br />
                                    <br />
                                    Inoltre, con il nostro aiuto, puoi <span class="highlighted">automatizzare operazioni ripetitive e che necessitano dell'impiego di più risorse</span>, sviluppando insieme a noi soluzioni informatiche personalizzate secondo le <span class="highlighted">esigenze della tua azienda</span>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Text Area  */}

                <Separator />

                <BoxServizi />

                <Separator />

                <BoxMetodo />

                <Footer />

            </main>
        </>
    )
}

export default ChiSiamo;
