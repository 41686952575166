import React from 'react'
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from "../elements/timeline/TimelineTwo";


const BoxMetodo = () => {
    return (
        <>
            <div className="rwt-timeline-area rn-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                textAlign="text-center"
                                radiusRounded=""
                                subtitle="Come lavoriamo"
                                title="Realizziamo il tuo progetto"
                                description="Definiamo assieme la <span class='highlighted'>strategia</span> corretta per raggiungere i tuoi <span class='highlighted'>obbiettivi</span> e <span class='highlighted'>analizziamo</span> le possibili soluzioni.<br /><span class='highlighted'>Sviluppiamo</span> il progetto e una volta concluso ti forniamo <span class='highlighted'>assistenza</span>."
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 mt--50">
                            <TimelineTwo classVar="dark-line" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BoxMetodo;
