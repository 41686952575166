import React from 'react'
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from './portfolio/PortfolioOne';

const BoxRealizzazioni = () => {
    return (
        <>
            <div className="rwt-portfolio-area rn-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                textAlign="text-center"
                                radiusRounded=""
                                subtitle="Realizzazioni"
                                title="Alcuni dei nostri ultimi progetti"
                                description=""
                            />
                        </div>
                    </div>
                    <PortfolioOne Column="col-lg-4 col-md-6 mt--30 portfolio" />
                </div>
            </div>
        </>
    )
}

export default BoxRealizzazioni;
