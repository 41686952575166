import React from 'react'
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AccordionOne from './accordion/AccordionOne';

const BoxFaq = () => {
    return (
        <>
            {/* Start Accordion Area  */}
            <div className="rn-accordion-area rn-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "Faq"
                                title = "Domande frequenti"
                                description = ""
                            />
                        </div>
                    </div>
                    <div className="row mt--35 row--20">
                        <div className="col-lg-10 offset-lg-1">
                            <AccordionOne customStyle="rn-accordion-02" />
                        </div>
                    </div>
                </div>
            </div>
            {/* End Accordion Area  */}
        </>
    )
}

export default BoxFaq;
