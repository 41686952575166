import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
//import ReactGA from "react-ga"; //Old version
import ReactGA from "react-ga4";

const GATracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes("localhost")) {

            //ReactGA.initialize("UA-6895267-1");
            ReactGA.initialize(
                [
                    {
                        trackingId:"UA-6895267-1"
                    }, 
                    {
                        trackingId:"G-EGMZ02WNCC" //Google Analytics 4
                    }, 
                    {
                        trackingId:"AW-1027784692" //Google Ads Conversions
                    }
                ]
            );

            //gtag("event", "conversion", {
            //    send_to: "AW-1027784692/MnJeCLLE5YcDEPT_iuoD"
            //  });
        }
        setInitialized(true);
    }, []);

    useEffect(() => {        
        if (initialized) {
        //ReactGA.pageview(location.pathname + location.search);
        ReactGA.send({ hitType: "pageview", page:location.pathname + location.search});        
        }
    }, [initialized, location]);
}

export default GATracker;