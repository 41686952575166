import React from 'react';
import {Link} from "react-router-dom";

const Menu = () => {
    return (
        <ul className="mainmenu">
            
            <li><Link to="/">Home</Link></li>

            <li><Link to="/chi-siamo">Chi siamo</Link></li>

            <li className="has-droupdown"><Link to="#">Sviluppiamo</Link>
                <ul className="submenu">
                    <li><Link to="/sviluppo-software-personalizzato">Software personalizzati</Link></li>
                    <li><Link to="/realizzazione-siti-web-ecommerce">Siti web / eCommerce</Link></li>
                    <li><Link to="/sviluppo-app-mobile-desktop">App desktop & mobile</Link></li>
                </ul>
            </li>
            
            <li><Link to="/consulenza">Consulenza</Link></li>

           
        </ul>
    )
}
export default Menu;
