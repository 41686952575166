import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiCheck, FiArrowRight } from "react-icons/fi";
import { Link } from 'react-router-dom';
import PhotoThanks from '../common/PhotoThanks';

const BoxSoftware = () => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
        <div className="container">
            <div className="row row--40 align-items-top">
                <div className="col-lg-6">
                    <div style={{ position: 'relative' }} >
                        <img className="radius-small" src="./images/split/split-software.jpg" alt="Corporate Image" />
                        <PhotoThanks name="Andrew Neel" link="andrewtneel" />
                    </div>
                </div>
                <div className="col-lg-6 mt_md--40 mt_sm--40">
                    <div className="content">
                        <div className="inner">
                            <h3 className="title">Software <strong>peronalizzato</strong></h3>
                           
                                <div className="title-wrapper">   
                                    <p>
                                        Lo <span class="highlighted">sviluppo di software personalizzato</span>, ovvero la creazione di programmi in grado di rispondere a <span class="highlighted">specifiche esigenze</span>, indica la serie di passi da svolgere per ottenere <span class="highlighted">risultati di alta qualità</span> e in <span class="highlighted">tempi prefissati</span> nello sviluppo di un prodotto o sistema software attraverso uno specifico progetto.
                                    </p>
                                    <p>
                                        Noi da anni creiamo, con ottimi risultati, <span class="highlighted">programmi su misura</span> specifici per le esigenze dei nostri clienti in provincia di <span class="highlighted">Milano, Como, Varese, Monza e Brianza</span>, definendo assieme a loro la strategia corretta per raggiungere gli obbiettivi prefissati, analizzando le possibili soluzioni, sviluppando il progetto e, una volta concluso, fornendo assistenza.
                                    </p>
                                    <p>
                                    Perché scegliere di sviluppare un software personalizzato?</p>
                                    <ul className="feature-list">
                                        <li>
                                            <div className="icon">
                                                <FiCheck />
                                            </div>
                                            <div className="title-wrapper">
                                                <p>Un software personalizzato <span class="highlighted">garantisce valore aggiunto</span> al tuo business.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <FiCheck />
                                            </div>
                                            <div className="title-wrapper">
                                                <p>Fatto secondo <span class="highlighted">le tue esigenze e i tuoi processi aziendali</span>, ottimizza tutte le attività lavorative, <span class="highlighted">migliorandone la gestione</span>.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <FiCheck />
                                            </div>
                                            <div className="title-wrapper">
                                                <p>Automatizza operazioni ripetitive che <span class="highlighted">necessitano dell'impiego di tempo e risorse</span>.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <FiCheck />
                                            </div>
                                            <div className="title-wrapper">
                                                <p>Interfacce personalizzate, <span class="highlighted">semplici ed intuitive</span>.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                               
                            <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}
                                    className="button-group mt--20">
                                <Link className="btn-default btn-icon float-right" to="/contatti?t=Sviluppo Software Personalizzato">Contattaci <i className="icon"><FiArrowRight /></i></Link>
                            </ScrollAnimation>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}




export default BoxSoftware
