import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiCheck, FiArrowRight } from "react-icons/fi";
import { Link } from 'react-router-dom';
import PhotoThanks from '../common/PhotoThanks';

const BoxWeb = () => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-top">
                    <div className="col-lg-6">
                        <div style={{ position: 'relative' }} >
                            <img className="radius-small" src="./images/split/split-web.jpg" alt="Corporate Image" />
                            <PhotoThanks name="Jon Tyson" link="jontyson" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">Siti web <strong>professionali</strong></h3>
                                <ul className="feature-list">
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <p>Realizziamo siti graficamente <span class="highlighted">accattivanti ed intuitivi</span> e curiamo <span class="highlighted">ogni particolare</span> per garantire <span class="highlighted">ottimi risultati</span>.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <p>Siti professionali, siti vetrina, blog ... qualsiasi sia la tua idea <span class="highlighted">noi la sviluppiamo.</span></p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <p>Basati su <span class="highlighted">piattaforme esistenti</span> o <span class="highlighted"> sviluppati completamente su misura</span>, scegliamo insieme la <span class="highlighted">tecnologia corretta</span> in base agli obbiettivi che si vogliono raggiungere.</p>
                                        </div>
                                    </li>
                                </ul>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}
                                    className="button-group mt--20 float-right">
                                        <Link className="btn-default btn-icon" to="/contatti?t=Sviluppo Sito Web">Contattaci <i className="icon"><FiArrowRight /></i></Link>
                                    </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    )
}




export default BoxWeb
