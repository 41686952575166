import React from 'react'

const PhotoThanks = ( {name, link} ) => {
    return (
        <div className="container-fluid">
            <div className="photo-thanks" >
                Photo by <a href={`https://unsplash.com/@${link}`} target="_blank" >{name}</a>{/* on <a href="https://unsplash.com/" target="_blank" >Unsplash</a> */}
            </div>
        </div>
    )
}
export default PhotoThanks;


