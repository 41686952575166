import React from 'react'
import SEO from "../common/SEO";
import Footer from '../common/footer/_Footer';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TopBar from '../common/header/_TopBar';
import Header from '../common/header/_Header';
import PhotoThanks from '../common/PhotoThanks';
import BoxConsulenza from '../elements/_BoxConsulenza';
import GATracker from '../gaTracker';

const Consulenza = () => {

    GATracker();

    return (
        <>
            <SEO title="Consulenza informatica" description={"Consulenza informatica"} />
            <main className="page-wrapper">
                
            <TopBar />
            <Header btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-550 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-consulenza.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">                                    
                                    <h1 className="title display-one">Consulenza<br/>Informatica</h1>
                                </div>
                            </div>
                        </div>                        
                    </div>                    
                </div>  
                <PhotoThanks name="Scott Graham" link="homajob" />         
                {/* End Slider Area  */}

                <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Figure professionali"
                                        title = "Aggiungi un professionista al tuo team"
                                        description = "Se necessiti di una figura prosfessionale da aggiungere al tuo team per brevi o lunghi periodi<br />oppure per un progetto specifico possiamo esserti utili sotto diversi aspetti."
                                     />
                                </div>
                            </div>
                            <BoxConsulenza
                               serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                               textAlign = "text-left"
                             />
                        </div>
                    </div>
      


                <Footer />

            </main>
        </>
    )
}

export default Consulenza;
