import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Quanto costa sviluppare un software personalizzato?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        Dipende dalla <span class="highlighted">tipologia</span> e dalla <span class="highlighted">complessità</span> del software di cui necessiti, il costo di un nostro software può partire dal <span class="highlighted">migliaio di Euro fino ad arrivare a decine di migliaia di Euro</span> nei casi più complessi.<br />
                        <br />
                        Se vuoi avere maggiori informazioni, capire la fattibilità del tuo progetto, e richiedere un preventivo, ti invitiamo a contattarci.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Quanto tempo ci vuole per realizzare un software personalizzato?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        I tempi di realizzazione di un nostro software personalizzato possono <span class="highlighted">variare molto in base alla dimensione e alla complessità del progetto</span>, quindi da qualche settimana fino a qualche mese.<br />
                        <br />
                        La creazione di un software personalizzato richiederà più tempo se:<br />
                        <ul>
                            <li style={{fontSize:16}}>È necessario creare un portale amministrativo, oltre ad una applicazione utente</li>
                            <li style={{fontSize:16}}>Deve integrarsi con più sistemi, ad esempio: sistemi di sicurezza, piattaforme di pagamento, CRM, ERP, HR, etc. </li>
                            <li style={{fontSize:16}}>Deve produrre report molto complessi e dettagliati, o migrare da un vecchio sistema</li>
                        </ul> 
                        Per una stima precisa della tempistica, ti invitiamo a contattarci.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

           

        </Accordion>
    )
}
export default AccordionOne

