import React from 'react'
import Typed from 'react-typed';
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import Header from '../common/header/_Header';
import TopBar from '../common/header/_TopBar';
import Separator from "../elements/separator/Separator";
import Footer from '../common/footer/_Footer';
import PhotoThanks from '../common/PhotoThanks';
import BoxServizi from '../elements/_BoxServizi';
import BoxMetodo from '../elements/_BoxMetodo';
import BoxRealizzazioni from '../elements/_BoxRealizzazioni';
import BoxFaq from '../elements/_BoxFaq';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import GATracker from '../gaTracker';

const Splash = () => {

    GATracker();

    return (
        <>
            <SEO title="Sviluppo software su misura" description="Sviluppo software su misura" />
            <main className="page-wrapper">


                <TopBar />
                <Header btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />


                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-850 bg_image" data-black-overlay="7" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-home.jpg)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h1 className="title theme-gradient display-two d-none d-md-block">Sviluppiamo<br />
                                        <Typed
                                            strings={[
                                                "software personalizzati",
                                                "gestionali su misura",
                                                "siti web / ecommerce",
                                                "app desktop e mobile"
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <h5 className="title theme-gradient display-two d-md-none">Sviluppo Software<br />Personalizzato</h5>
                                    <p className="description d-none d-md-block">Con uno sguardo al futuro definiamo assieme a te la strategia corretta per raggiungere i tuoi obbiettivi.</p>
                                    <div className="button-group">
                                        <a className="btn-default btn-medium round btn-icon btn-second-color" href="#services">Approfondisci<i className="icon"><FiArrowRight /></i></a>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contattaci<i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PhotoThanks name="Charles Forerunner" link="charles_forerunner" />
                {/* End Slider Area  */}

                <Separator />

                <BoxServizi />

                <Separator />

                <BoxMetodo />

                <Separator />                

                {/* Start Elements Area  */}
                <div className="rwt-callto-action-area rn-section-gap">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                {/* End Elements Area  */}
                
                <Separator />

                <BoxRealizzazioni />

                <Footer />

               
            </main>

        </>
    )
}

export default Splash;