import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <img src="/images/icons/marker.png" style={{height: 64, marginLeft: -32, marginTop: -64}} />;
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 45.626986,
      lng: 9.0757925
    },
    zoom: 10
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAgxyNkPvbtzO6Me6GDqTpX09jYFSiGQ8o' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={45.626986}
            lng={9.0757925}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapStyle;