import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 

import HomePage from "./pages/_HomePage";
import ChiSiamo from './pages/_ChiSiamo';
import Contatti from './pages/_Contatti';
import SviluppoSoftware from './pages/_SviluppoSoftware';
import SviluppoWeb from './pages/_SviluppoWeb';
import SviluppoApp from './pages/_SviluppoApp';
import Consulenza from './pages/_Consulenza';
import Privacy from './pages/_Privacy';

// Import Css Here 
import './assets/scss/style.scss';

const App = () => { 

    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomePage}/>                    
                    <Route path={`${process.env.PUBLIC_URL + "/chi-siamo"}`} exact component={ChiSiamo}/>          
                    <Route path={`${process.env.PUBLIC_URL + "/contatti"}`} exact component={Contatti}/>        
                    <Route path={`${process.env.PUBLIC_URL + "/sviluppo-software-personalizzato"}`} exact component={SviluppoSoftware}/>
                    <Route path={`${process.env.PUBLIC_URL + "/realizzazione-siti-web-ecommerce"}`} exact component={SviluppoWeb}/>
                    <Route path={`${process.env.PUBLIC_URL + "/sviluppo-app-mobile-desktop"}`} exact component={SviluppoApp}/>
                    <Route path={`${process.env.PUBLIC_URL + "/consulenza"}`} exact component={Consulenza}/>
                    <Route path={`${process.env.PUBLIC_URL + "/privacy-cookie-policy"}`} exact component={Privacy}/>                   
                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
