import React from 'react'
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";

const BoxServizi = () => {
    return (
        <>
            <div className="rn-service-area rn-section-gap" id="services">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                textAlign="text-center"
                                radiusRounded=""
                                subtitle="Servizi"
                                title="Scopri le nostre soluzioni su misura per te"
                                description="Mediante l'utilizzo di tecnologie <span class='highlighted'>sempre più innovative</span> proponiamo<br /><span class='highlighted'>soluzioni efficaci ed intuitive</span> con interfacce grafiche accattivanti e facili da utilizzare."
                            />
                        </div>
                    </div>
                    <ServiceOne
                        serviceStyle="service__style--1 bg-color-blackest radius mt--25"
                        textAlign="text-center"
                    />
                </div>
            </div>
        </>
    )
}

export default BoxServizi;
