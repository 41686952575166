import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiCheck, FiArrowRight } from "react-icons/fi";
import { Link } from 'react-router-dom';
import PhotoThanks from '../common/PhotoThanks';

const BoxApp = () => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
        <div className="container">
            <div className="row row--40 align-items-top">
                <div className="col-lg-6">
                    <div style={{ position: 'relative' }} >
                        <img className="radius-small" src="./images/split/split-app.jpg" alt="Corporate Image" />
                        <PhotoThanks name="Rodion Kutsaev" link="frostroomhead" />
                    </div>
                </div>
                <div className="col-lg-6 mt_md--40 mt_sm--40">
                    <div className="content">
                        <div className="inner">
                            <h3 className="title">Applicazioni <strong>mobile</strong></h3>
                            <ul className="feature-list">
                                <li>
                                    <div className="icon">
                                        <FiCheck />
                                    </div>
                                    <div className="title-wrapper">                                        
                                    <p>Sviluppiamo App native <span class="highlighted">Android</span> e <span class="highlighted">iOS</span>.</p>
                                   </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <FiCheck />
                                    </div>
                                    <div className="title-wrapper">
                                    <p>Vuoi vendere online i tuoi prodotti o i tuoi servizi oppure <span class="highlighted">hai un'idea originale ed innovativa?</span> ... qualsiasi sia la tua idea <span class="highlighted">noi la sviluppiamo per te.</span></p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <FiCheck />
                                    </div>
                                    <div className="title-wrapper">
                                    <p>Con un App puoi <span class="highlighted">sfruttare le potenzialità</span> offerte dai device di ultima generazione e raggiungere i tuoi clienti con le <span class="highlighted">notifiche sul dispositivo</span>.</p>
                                   </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <FiCheck />
                                    </div>
                                    <div className="title-wrapper">
                                    <p>Creiamo qualcosa di nuovo sfruttando <span class="highlighted">geolocalizzazione, fotocamera, microfono</span> ... </p>
                                 </div>
                                </li>                               
                            </ul>
                            <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}
                                    className="button-group mt--20">
                                        <Link className="btn-default btn-icon float-right" to="/contatti?t=Sviluppo e-Commerce">Contattaci <i className="icon"><FiArrowRight /></i></Link>
                                    </ScrollAnimation>     
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}




export default BoxApp
