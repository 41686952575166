import React from 'react'
import SEO from "../common/SEO";
import Footer from '../common/footer/_Footer';
import TopBar from '../common/header/_TopBar';
import Header from '../common/header/_Header';
import PhotoThanks from '../common/PhotoThanks';
import GATracker from '../gaTracker';

const Privacy = () => {

    GATracker();

    return (
        <>
            <SEO title="Privacy policy" description={"Privacy & cookie policy"} />
            <main className="page-wrapper">
                
            <TopBar />
            <Header btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-450 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-privacy.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                   
                                    <h1 className="title display-one">Privacy & Cookie<br/>Policy</h1>
                                </div>
                            </div>
                        </div>                        
                    </div>                    
                </div>  
                <PhotoThanks name="Joshua Sortino" link="sortino" />  
                {/* End Slider Area  */}


                <div className="service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="content">
                            
                                La <span class="highlighted">Pizzi Web</span>, ai sensi dell'art. 13 del D. Lgs. 30.6.2003 n. 196, fornisce la seguente informativa:<br />
                                a.  i dati personali sono richiesti per le seguenti finalità:<br />
                                1.	gestione del servizio da Voi prescelto nella presente sezione;<br />
                                2.	comunicazioni promozionali, indagini di mercato, analisi economico/statistiche, rilevazioni del grado di soddisfazione della clientela. Potranno inoltre essere utilizzati per attività di marketing o di informazioni commerciali volte a premiare o favorire i clienti in ragione agli eventuali acquisti attraverso l'invio di locandine pubblicitarie e documentazione promozionale cartacea, e-mail, sms, mms.<br />
                                a.	Il conferimento dei dati, con riferimento alle finalità di cui al punto a1), ha natura obbligatoria ed un eventuale rifiuto a rispondere comporta l'impossibilità per la Pizzi Web di fornire il relativo servizio. Il conferimento dei dati di cui al punto a2) ha natura facoltativa.<br />
                                b.	Per le finalità di cui al precedente punto a2) i dati, strettamente necessari a ciascuna tipologia di trattamento, potranno essere comunicati dalla Pizzi Web da una propria divisione all'altra e alle società del gruppo.<br />
                                c.	I dati, strettamente necessari al trattamento, potranno essere comunicati al partner tecnologico e strumentale di cui il titolare si avvale per l'erogazione del servizio richiesto.<br />
                                d.	Il trattamento dei Dati avviene mediante l'utilizzo di strumenti manuali, informatici e telematici, da parte di strutture della Pizzi Web. In ogni caso il trattamento avviene con logiche strettamente correlate alle finalità sopra indicate e, comunque, in modo da garantire la sicurezza e la riservatezza dei Dati, i quali saranno altresì gestiti e protetti in ambienti il cui accesso è sotto costante controllo.<br />
                                e.	Titolare del trattamento è la Pizzi Web, con sede legale in via della Pace 30 a Ceriano Laghetto, nella persona del Legale Rappresentante mentre i Responsabili del trattamento sono conoscibili scrivendo a 'Pizzi Web via della Pace 30 - 20816 - Ceriano Laghetto (MB)' o telefonando al Numero 02 87187833.<br />
                                f.	In relazione al trattamento dei predetti dati l'interessato, ai sensi dell'art. 7 del D. Lgs. n. 196/2003, ha diritto di ottenere:<br />
                                1.	la conferma dell'esistenza o meno dei suoi Dati, la loro comunicazione in forma comprensibile e la conoscenza della loro origine nonché della logica su cui si basa il trattamento;<br />
                                2.	la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione della legge;<br />
                                3.	l'aggiornamento dei Dati, la loro rettificazione o, quando vi abbia interesse, la loro integrazione;<br />
                                4.	l'attestazione che le operazioni di cui ai precedenti punti 2 e 3, sono state portate a conoscenza di coloro ai quali sono stati comunicati, sempre che non risulti impossibile o comporti un impiego di mezzi sproporzionato.<br />
                                Il Cliente ha inoltre diritto di opporsi per motivi legittimi al trattamento dei dati.<br />
                                Ai sensi e per gli effetti di cui all'art. 24, comma 1, lettera b), per le finalità di cui al punto a1) il consenso non è necessario.<br />
                                L'interessato esprime, ai sensi dell'art. 23 del D.Lgs. 196/2003, il proprio consenso al trattamento dei dati per le finalità di cui al punto a2) e alla comunicazione dei dati secondo le modalità indicate al precedente punto c).<br />
                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />

            </main>
        </>
    )
}

export default Privacy;
