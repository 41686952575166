import React , {useEffect, useState} from 'react';
import axios from 'axios';
import { getUrlParameter } from '../../utils/script';
import ReactGA from "react-ga4";

const Result = () => {
    return (
        <p className="success-message">Il tuo messaggio è stato inviato con successo. Ti risponderemo il prima possibile.</p>
    )
}

function ContactForm({props , formStyle}) {
    const [ result, showresult ] = useState(false);
    const [ nominativo, setNominativo ] = useState();
    const [ email, setEmail ] = useState();
    const [ telefono, setTelefono ] = useState();
    const [ titolo, setTitolo ] = useState(getUrlParameter('t') ? getUrlParameter('t') : "");
    const [ messaggio, setMessaggio ] = useState();

    useEffect(() => {        
        setTitolo(getUrlParameter('t'))
    }, [getUrlParameter('t')]);

    const sendEmail = (e) => {
        e.preventDefault();
        console.log('form?',nominativo,email,telefono,titolo,messaggio);
        axios
            .post(
                'https://www.pizziweb.it/services/api/notify',
                    {
                    code: '2k22pws',                   
                    action: 'sendMail',
                    mail: {
                        subject: 'Richiesta contatto',
                        displayName: 'Pizzi Web Solutions',
                        mailFrom: 'info@pizziweb.it',
                        mailTo: email,
                        templateName: 'RichiestaContatto',
                        templateBody: `<b>Nominativo:</b> ${nominativo}<br /><b>eMail:</b> ${email}<br /><b>Recapito telefonico:</b> ${telefono}<br /><b>Oggetto:</b> ${titolo}<br /><b>Richiesta:</b> ${messaggio}`                        
                    }
                }
            )
            .then((res) => {
                e.target.reset();
                setNominativo();
                setEmail();
                setTelefono();
                setTitolo();
                setMessaggio();
                showresult(true);
                ReactGA.event("conversion", { send_to: "AW-1027784692/MnJeCLLE5YcDEPT_iuoD" })
                return true //res.data;
        })        
    };

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <input 
                type="text"
                name="fullname"
                placeholder="Nome / Cognome"
                required
                onChange={(event) => { setNominativo(event.target.value) }}
                />
            </div>

            <div className="form-group">
                <input 
                type="email"
                name="email"
                placeholder="Email"
                required
                onChange={(event) => { setEmail(event.target.value) }}
                />
            </div>

            <div className="form-group">
                <input 
                type="text"
                name="phone"
                placeholder="Recapito telefonico"
                onChange={(event) => { setTelefono(event.target.value) }}                
                />
            </div>


            <div className="form-group">
                <input 
                type="text"
                name="subject"
                placeholder="Titolo"
                required
                value={titolo}
                onChange={(event) => { setTitolo(event.target.value) }}
                />
            </div>

            <div className="form-group">
                <textarea 
                name="message"
                placeholder="Messaggio"
                required
                onChange={(event) => { setMessaggio(event.target.value) }}
                >
                </textarea>
            </div>

            <div className="form-group">
                <button className="btn-default btn-large">Invia</button>
            </div> 

            <div className="form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ContactForm;
